import {addRoutes, addRoute} from "@pazion/pazion-core/src/router";
import "@pazion/pazion-core/src/config";
import {getCatalogRoutes} from "@pazion/pazion-catalog/src/module-config";
import CardsListing from "@pazion/pazion-catalog/src/views/cards/CardsListing.vue";

addRoutes(getCatalogRoutes());

addRoute(  {
    path: '/',
    name: 'Home',
    component: CardsListing,
    meta: {
        'show-in-nav': false,
        roles: ['user'],
        sort: -55
    },
});
